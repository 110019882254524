import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/Login'
import Welcome from '../components/Welcome'
import Tags from '../components/Tags'
// import Appabout from '../components/App_about'
import Apprecommend from '../components/App_recommend'
import Banner from '../components/Banner'
// import Huobicontroller from '../components/Huobi_controller'
// import Testcontroller from '../components/Test_controller'
import Coin from '../components/Coin'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/tags', component: Tags },
      { path: '/appRecommend', component: Apprecommend },
      { path: '/coin', component: Coin },
      // { path: '/appAbout', component: Appabout },
      { path: '/banner', component: Banner }
      // { path: '/huobi-controller', component: Huobicontroller }
      // { path: '/test-controller', component: Testcontroller }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   // to 将要访问的路径
//   // from 代表从哪个路径跳转而来
//   // next 是一个函数，表示放行
//   //     next()  放行    next('/login')  强制跳转
//   if (to.path === '/login') return next()
//   // 获取token
//   const tokenStr = window.sessionStorage.getItem('token')
//   if (!tokenStr) return next('/login')
//   next()
// })

export default router
