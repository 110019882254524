<template>
  <div class="">
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showAdd()">新增</el-button>
      <el-table :data="bannerList" border stripe>
        <el-table-column type="index" align="center" label="序号" width="50px"></el-table-column>
        <el-table-column label="名称" prop="title" align="center" width="100px">
        </el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.ico" alt="" width="40px" height="35px" />
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="link" align="center" show-overflow-tooltip>
          <template v-slot="scope">
            <!-- <span style="color: blue;">{{scope.row.link}}</span> -->
            <a target="blank" :href="scope.row.link">{{scope.row.link}}</a>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort" align="center" width="90px"></el-table-column>
        <el-table-column label="是否跳转" align="center" width="80px">
          <template v-slot='scope'>
            <span v-if="scope.row.linkAble==1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="warning" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)"></el-button>
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增轮播图对话框 -->
      <el-dialog title="新增轮播图" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <!-- 内容主体区域 -->
        <el-form :model="addForm" ref="addFormRef"   label-width="70px">
        <el-form-item label="名称" prop="title" label-width="100px">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="ico" label-width="100px">
          <el-button type="primary" size="mini" @click="showUpload()">新增图片</el-button>
          <el-input v-model="addForm.ico" placeholder="请输入地址或上传图标,图标须小于500k"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link" label-width="100px">
          <el-input v-model="addForm.link"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100px">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否跳转" prop="linkAble" label-width="100px">
          <el-select v-model="addForm.linkAble">
            <el-option label="是" value=1></el-option>
            <el-option label="否" value=0></el-option>
          </el-select>
        </el-form-item>
        </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 修改轮播图对话框 -->
      <el-dialog title="修改轮播图" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
        <!-- 内容主体区域 -->
        <el-form :model="editForm" ref="editFormRef"   label-width="70px">
        <el-form-item label="名称" prop="title" label-width="100px">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="ico" label-width="100px">
          <el-button type="primary" size="mini" @click="showEdit()">修改图片</el-button>
          <el-input v-model="editForm.ico"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link" label-width="100px">
          <el-input v-model="editForm.link"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100px">
          <el-input v-model="editForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否跳转" prop="linkAble" label-width="100px">
          <el-select v-model="editForm.linkAble">
            <el-option label="是" :value=1></el-option>
            <el-option label="否" :value=0></el-option>
          </el-select>
        </el-form-item>
        </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 图标上传或修改 -->
      <el-dialog :title="title" :visible.sync="DialogVisible" width="50%">
      <!-- 内容主体区域 -->
      <el-form :model="addOrEditForm" :rules="editFormRules" ref="addOrAddFormRef" label-width="70px">
        <el-form-item label="上传图标" v-if="title === '上传'">
          <input type="file" id='doc'>
        </el-form-item>
        <el-form-item label="修改图标" v-else>
          <input type="file" id='doc'>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="load">确 定</el-button>
      </span>
    </el-dialog>
    </el-card>
  </div>
</template>
<script>
import Oss from 'ali-oss'
export default {
  data () {
    return {
      res: {},
      total: 0,
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      query: {},
      addDialogVisible: false,
      editDialogVisible: false,
      DialogVisible: false,
      bannerList: [],
      addForm: {},
      editForm: {},
      editForm1: {},
      title: '',
      addOrEditForm: {
        editFile: {},
        loadFile: {}
      },
      editFormRules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        ico: [{ required: true, message: '请输入图片地址', trigger: 'blur' }],
        link: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        // remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        linkAble: [{ required: true, message: '请选择是否热门', trigger: 'blur' }]
      }
    }
  },
  methods: {
    async load () {
      // console.log(this.res)
      const client = new Oss({
        region: 'oss-cn-beijing',
        accessKeyId: this.res.accessKeyId,
        accessKeySecret: this.res.accessKeySecret,
        stsToken: this.res.securityToken,
        bucket: 'walletimage',
        secure: true
      })
      const docObj = document.getElementById('doc')
      const file = docObj.files[0]
      var val = docObj.value
      var fileSize = file.size
      var size = fileSize / 1024
      var fileMaxSize = 500
      if (size > fileMaxSize) return this.$message.error('图标不得大于500k')
      var now = new Date().getTime()
      var suffix = 'banner_' + this.getDate(now) + val.substr(val.indexOf('.'))
      // console.log(client, docObj.files[0], this.res)
      const result = await client.multipartUpload(suffix, file)
      // console.log(result, this.addForm.appIco)
      this.DialogVisible = false
      if (this.title === '上传') this.addForm.ico = result.res.requestUrls[0]
      this.editForm.ico = result.res.requestUrls[0]
    },
    async getSts () {
      const { data: res } = await this.$http.post('index/sts')
      this.res = res.data
    },
    // 图标操作
    showUpload() {
      this.title = '上传'
      this.DialogVisible = true
      this.getSts()
    },
    showEdit() {
      this.title = '修改'
      this.DialogVisible = true
      this.getSts()
    },
    showAdd() {
      this.addDialogVisible = true
    },
    async getInfo() {
      // console.log('getinfo')
      this.query = this.queryInfo
      const { data: res } = await this.$http.get('banner/list')
      this.bannerList = res.data
      // console.log(res)
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    addConfirm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return this.$message.error('请填写完整信息')
        const { data: res } = await this.$http.post('banner/create', this.addForm)
        // console.log(res)
        if (res.status !== 0) return this.$message.error('添加数据失败')
        this.addDialogVisible = false
        this.getInfo()
      })
    },
    editDialogClosed() {
      this.editForm = this.editForm1
    },
    showEditDialog (row) {
      // console.log(row)
      this.editForm = row
      this.editForm1 = row
      this.editDialogVisible = true
    },
    async editConfirm() {
      const { data: res } = await this.$http.post('banner/update', this.editForm)
      // console.log(res)
      if (res.status !== 0) return this.$message.error('修改数据失败')
      this.getInfo()
      this.editDialogVisible = false
    },
    async removeById(Id) {
      // console.log(Id)
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('banner/delete', { params: { id: Id } })
      if (res.status !== 0) {
        return this.$message.error('删除用户失败！')
      }
      this.$message.success('删除用户成功！')
      this.getInfo()
    },
    getDate (time) {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth() < 10 ? date.getMonth() + 1 : date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const nowTime = year + '-' + month + '-' + day + '_' + hh + '-' + mm + '-' + ss
      return nowTime
    }
  },
  created() {
    this.getInfo()
  }
}
</script>
<style lang='less' scoped>
</style>
