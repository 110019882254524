<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <span>管理系统</span>
      </div>
      <el-button type="info" class="outBut" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '70px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" unique-opened :collapse="isCollapse" :collapse-transition="false" router>
          <!-- 一级菜单 -->
          <el-menu-item :index="item.id + ''" v-for="item in menulist" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-menu"></i>
              <!-- 文本 -->
              <span>{{item.authName}}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main id="main" style="margin-top: 60px;">
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      menulist: [
        { authName: '标签', id: 'tags' },
        { authName: '热门应用', id: 'appRecommend' },
        { authName: '热门币种', id: 'coin' },
        { authName: '轮播图', id: 'banner' }
        // { authName: 'appAbout', id: 'appAbout' },
        // { authName: 'huobi-controller', id: 'huobi-controller' },
        // { authName: 'test-controller', id: 'test-controller' }
      ],
      isCollapse: false,
      activePath: ''
    }
  },
  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        document.getElementById('main').classList.add('main1')
      } else {
        document.getElementById('main').classList.remove('main1')
      }
    },
    logout () {
      this.$router.push('/login')
      window.sessionStorage.clear()
    }
  }
}
</script>
<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.outBut {
  position: absolute;
  right: 40px;
}
.el-aside {
  position: fixed;
  top: 60px;
  height: 100%;
}
.el-header {
  position: fixed;
  width: 100%;
  z-index: 999;
}
.el-main {
  margin-left: 200px;
}
.main1 {
  margin-left: 70px!important;
}
</style>
