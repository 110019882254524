<template>
  <div class="">
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>热门推荐</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showAdd()">新增</el-button>
      <el-table :data="appRecommendList" border stripe>
        <el-table-column type="index" align="center" label="序号" width="50px"></el-table-column>
        <el-table-column label="应用名称" prop="appName" align="center"></el-table-column>
        <el-table-column label="图标" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.appIco" alt="" width="35px" height="35px" />
          </template>
        </el-table-column>
        <el-table-column label="链接" align="center" show-overflow-tooltip width="80px">
          <template v-slot="scope">
            <a target="blank" :href="scope.row.appLink">安卓链接</a>
          </template>
        </el-table-column>
        <el-table-column label="ios链接" align="center" show-overflow-tooltip width="80px">
          <template v-slot="scope">
            <a :href="scope.row.iosLink">ios链接</a>
          </template>
        </el-table-column>
        <el-table-column label="描述" prop="desc" align="center" show-overflow-tooltip width="280px"></el-table-column>
        <el-table-column label="状态" align="center" width="60px">
          <template v-slot="scope">
            <span v-if="scope.row.status === 0">下架</span>
            <span v-else>上架</span>
          </template>
          </el-table-column>
        <el-table-column label="是否热门" prop="hot" align="center" width="80px">
          <template v-slot="scope">
            <span v-if="scope.row.hot === 1">热门</span>
            <span v-else>非热门</span>
          </template>
        </el-table-column>
        <el-table-column label="tag标签" align="center" width="120px">
          <template v-slot="scope">
            <el-tag>{{tagsArr[scope.row.tag - 1]}}</el-tag>
            <!-- <el-tag size="mini" v-for="item in demoArr" :key=item>{{tagsList[item - 1].tag}}</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.type === 1">以太坊</span>
            <span v-else-if="scope.row.type === 2">波场</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updateTime" align="center" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="warning" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)"></el-button>
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
          <!-- 修改热门的对话框 -->
    <el-dialog title="修改热门" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="应用名称" prop="appName">
          <el-input v-model="editForm.appName"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="appIco">
          <el-button type="primary" size="mini" @click="showEdit()">修改图标</el-button>
          <el-input v-model="editForm.appIco"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="appLink">
          <el-input v-model="editForm.appLink"></el-input>
        </el-form-item>
        <el-form-item label="ios链接" prop="iosLink">
          <el-input v-model="editForm.iosLink"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="editForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="editForm.status">
            <el-option label="上架" :value=1></el-option>
            <el-option label="下架" :value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否热门" prop="hot">
          <el-select v-model="editForm.hot">
            <el-option label="热门" :value=1></el-option>
            <el-option label="非热门" :value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="tag标签">
          <el-select v-model="editForm.tag">
            <el-option v-for="item in tagsList" :key="item.id" :label="item.tag" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type">
            <el-option label="以太坊" :value=1></el-option>
            <el-option label="波场" :value=2></el-option>
          </el-select>
          </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
    </el-dialog>
      <!-- 新增热门的对话框 -->
      <el-dialog title="新增热门" :visible.sync="addDialogVisible" width="50%"  @close="addDialogClosed">
        <!-- 内容主体区域 -->
        <el-form :model="addForm" :rules="editFormRules" ref="addFormRef"   label-width="80px">
        <el-form-item label="应用名称" prop="appName">
          <el-input v-model="addForm.appName"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="appIco">
          <el-button type="primary" size="mini" @click="showUpload()">上传图标</el-button>
          <el-input v-model="addForm.appIco" placeholder="请输入地址或上传图标,图标须小于500k"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="appLink">
          <el-input v-model="addForm.appLink"></el-input>
        </el-form-item>
        <el-form-item label="ios链接" prop="iosLink">
          <el-input v-model="addForm.iosLink"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="addForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="addForm.status">
            <el-option label="上架" value=1></el-option>
            <el-option label="下架" value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否热门" prop="hot">
          <el-select v-model="addForm.hot">
            <el-option label="热门" value=1></el-option>
            <el-option label="非热门" value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="tag标签" prop="tag">
          <el-select v-model="addForm.tag">
            <el-option v-for="item in tagsList" :key="item.id" :label="item.tag" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="addForm.type">
            <el-option label="以太坊" value=1></el-option>
            <el-option label="波场" value=2></el-option>
          </el-select>
        </el-form-item>
        </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 图标上传或修改 -->
      <el-dialog :title="title" :visible.sync="DialogVisible" width="50%"   @close="DialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="addOrEditForm" :rules="editFormRules" ref="addOrAddFormRef" label-width="70px">
        <el-form-item label="上传图标" v-if="title === '上传'">
          <input type="file" id='doc'>
        </el-form-item>
        <el-form-item label="修改图标" v-else>
          <input type="file" id='doc'>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="load">确 定</el-button>
      </span>
    </el-dialog>
    </el-card>
  </div>
</template>
<script>
import Oss from 'ali-oss'
// import { set } from 'vue/types/umd'
export default {
  data () {
    return {
      demoArr: [1, 2, 3, 4],
      res: {},
      queryInfo: {
        page: 1,
        rows: 10,
        type: 1
      },
      appRecommendList: [],
      tagsList: [],
      tagsArr: [],
      editForm: {},
      editForm1: {},
      editDialogVisible: false,
      addDialogVisible: false,
      DialogVisible: false,
      addForm: {},
      title: '',
      addOrEditForm: {
        editFile: {},
        loadFile: {}
      },
      // 修改表单的验证规则对象
      editFormRules: {
        appName: [{ required: true, message: '请输入应用名', trigger: 'blur' }],
        appIco: [{ required: true, message: '请输入图标地址', trigger: 'blur' }],
        appLink: [{ required: true, message: '请输入安卓链接', trigger: 'blur' }],
        iosLink: [{ required: true, message: '请输入ios链接', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        hot: [{ required: true, message: '请选择热门程度', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择标签', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        id: [{ required: true, message: '请选择类型', trigger: 'blur' }]
      },
      arr: [
        {
          value: '1',
          label: '热门'
        }, {
          value: '0',
          label: '非热门'
        }
      ]
    }
  },
  methods: {
    async load () {
      // console.log(this.res)
      const client = new Oss({
        region: 'oss-cn-beijing',
        accessKeyId: this.res.accessKeyId,
        accessKeySecret: this.res.accessKeySecret,
        stsToken: this.res.securityToken,
        bucket: 'anypayimage',
        secure: true
      })
      const docObj = document.getElementById('doc')
      const file = docObj.files[0]
      var val = docObj.value
      var fileSize = file.size
      var size = fileSize / 1024
      var fileMaxSize = 500
      if (size > fileMaxSize) return this.$message.error('图标不得大于500k')
      var now = new Date().getTime()
      var suffix = 'appRecommend_' + this.getDate(now) + val.substr(val.indexOf('.'))
      // console.log(client, docObj.files[0], this.res)
      const result = await client.multipartUpload(suffix, file)
      // console.log(result, this.addForm.appIco)
      this.DialogVisible = false
      if (this.title === '上传') this.addForm.appIco = result.res.requestUrls[0]
      this.editForm.appIco = result.res.requestUrls[0]
    },
    DialogClosed() {
    },
    showAdd() {
      this.addDialogVisible = true
    },
    // 图标操作
    showUpload() {
      this.title = '上传'
      this.DialogVisible = true
      this.getSts()
    },
    showEdit() {
      this.title = '修改'
      this.DialogVisible = true
      this.getSts()
    },
    async addConfirm() {
      // this.addForm.id = 40
      // this.addForm.sort = 40
      // console.log(this.addForm)
      const { data: res } = await this.$http.post('appRecommend/create', this.addForm)
      // console.log(res)
      if (res.status !== 0) return this.$message.error('添加数据失败')
      this.addDialogVisible = false
      this.getInfo()
    },
    editConfirm () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return this.$message.error('请完善修改信息!')
        delete this.editForm.createTime
        delete this.editForm.updateTime
        delete this.editForm.sort
        // console.log(this.editForm)
        const { data: res } = await this.$http.post('appRecommend/update', this.editForm)
        if (res.status !== 0) return this.$message.error('修改数据失败')
        this.editDialogVisible = false
        // console.log(res)
        this.getInfo()
      })
    },
    addDialogClosed() {
      this.addForm = {}
    },
    editDialogClosed() {
      this.editForm = this.editForm1
    },
    async getInfo () {
      const { data: res } = await this.$http.get('appRecommend/list?' + this.queryInfo.page + '&page=' + this.queryInfo.rows + '&type=' + this.queryInfo.type)
      this.appRecommendList = res.data.appRecommends
      // console.log(res, 'list')
    },
    async getTagsInfo () {
      const { data: res } = await this.$http.get('tags/list')
      this.tagsList = res.data
      this.tagsList.map(item => {
        this.tagsArr[item.id - 1] = item.tag
      })
      // console.log(this.tagsList, 'tagList', this.tagsArr)
      this.getInfo()
    },
    showEditDialog (row) {
      // console.log(row)
      this.editForm = row
      this.editForm1 = row
      this.editDialogVisible = true
    },
    async removeById (id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该热门, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      const { data: res } = await this.$http.post('appRecommend/delete?id=' + id)

      if (res.status !== 0) {
        return this.$message.error('删除热门失败！')
      }

      this.$message.success('删除热门成功！')
      this.getInfo()
    },
    async getSts () {
      const { data: res } = await this.$http.post('index/sts')
      this.res = res.data
    },
    getDate (time) {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth() < 10 ? date.getMonth() + 1 : date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const nowTime = year + '-' + month + '-' + day + '_' + hh + '-' + mm + '-' + ss
      return nowTime
    }
  },
  filters: {
    // tagName (val) {
    //   // const that = this
    //   // setTimeout(() => {
    //   this.tagsList.map(item => {
    //     if (item.id === val) {
    //       return item.tag
    //     }
    //   })
    //   // }, 2000)
    // }
  },
  created () {
  },
  mounted () {
    this.getTagsInfo()
  }
}
</script>
<style lang='less' scoped>
.el-tag {
  margin-right: 3px;
}
</style>
