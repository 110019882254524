<template>
  <div class="">
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数字货币</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showAdd()">新增</el-button>
      <el-table :data="coinList" border stripe>
        <el-table-column type="index" align="center" label="序号" width="50px"></el-table-column>
        <el-table-column label="符号" prop="symbol" align="center" width="100px">
        </el-table-column>
        <el-table-column label="代币地址" prop="address" align="center"></el-table-column>
        <el-table-column label="官网" prop="uccn" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="主链" prop="chain" align="center" width="80px">
          <template v-slot='scope'>
            <span v-if="scope.row.chain==1">以太坊</span>
            <span v-else-if="scope.row.chain == 2">波场</span>
            <span v-else-if="scope.row.chain == 3">比特币</span>
            <span v-else-if="scope.row.chain == 4">币安</span>
            <span v-else-if="scope.row.chain == 5">火必</span>
          </template>
        </el-table-column>
        <el-table-column label="是否热门" prop="hot" align="center" width="80px">
          <template v-slot='scope'>
            <span v-if="scope.row.hot==1">热门</span>
            <span v-else>非热门</span>
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remark" align="center" width="200px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" align="center" width="160px">
        </el-table-column>
        <el-table-column label="排序" prop="sort" align="center" width="90px">
        </el-table-column>
        <el-table-column label="精度" prop="decimalField" align="center" width="90px">
        </el-table-column>
        <el-table-column label="图标" align="center">
          <template v-slot="scope">
            <img :src="scope.row.tokenIcon" alt="" width="40px" height="35px" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="warning" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)"></el-button>
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增数字货币的对话框 -->
      <el-dialog title="新增币种" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <!-- 内容主体区域 -->
        <el-form :model="addForm" :rules="formRules" ref="addFormRef"   label-width="70px">
        <el-form-item label="符号" prop="symbol" label-width="100px">
          <el-input v-model="addForm.symbol"></el-input>
        </el-form-item>
        <el-form-item label="代币地址" prop="address" label-width="100px">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="官网" prop="uccn" label-width="100px">
          <el-input v-model="addForm.uccn"></el-input>
        </el-form-item>
        <el-form-item label="主链" prop="chain" label-width="100px">
          <el-select v-model="addForm.chain">
            <el-option label="以太坊" value=1></el-option>
            <el-option label="波场" value=2></el-option>
            <el-option label="比特币" value=3></el-option>
            <el-option label="币安" value=4></el-option>
            <el-option label="火必" value=5></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否热门" prop="hot" label-width="100px">
          <el-select v-model="addForm.hot">
            <el-option label="热门" value=1></el-option>
            <el-option label="非热门" value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100px">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="精度" prop="decimalField" label-width="100px">
          <el-input v-model="addForm.decimalField"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="tokenIcon" label-width="200px">
          <el-input v-model="addForm.tokenIcon"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark" label-width="100px">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
        </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 修改货币的对话框 -->
      <el-dialog title="修改货币" :visible.sync="editDialogVisible" width="50%">
        <!-- 内容主体区域 -->
        <el-form :model="editForm" ref="editFormRef" label-width="70px" :rules="formRules">
        <el-form-item label="符号" prop="symbol" label-width="100px">
          <el-input v-model="editForm.symbol"></el-input>
        </el-form-item>
        <el-form-item label="代币地址" prop="address" label-width="100px">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="官网" prop="uccn" label-width="100px">
          <el-input v-model="editForm.uccn"></el-input>
        </el-form-item>
        <el-form-item label="主链" prop="chain" label-width="100px">
          <el-select v-model="editForm.chain">
            <el-option label="以太坊" :value=1></el-option>
            <el-option label="波场" :value=2></el-option>
            <el-option label="比特币" :value=3></el-option>
            <el-option label="币安" :value=4></el-option>
            <el-option label="火必" :value=5></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否热门" prop="hot" label-width="100px">
          <el-select v-model="editForm.hot">
            <el-option label="热门" :value=1></el-option>
            <el-option label="非热门" :value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100px">
          <el-input v-model="editForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="精度" prop="decimalField" label-width="100px">
          <el-input v-model="editForm.decimalField"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="tokenIcon" label-width="200px">
          <el-input v-model="editForm.tokenIcon"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark" label-width="100px">
          <el-input v-model="editForm.remark"></el-input>
        </el-form-item>
        </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
      </el-dialog>
      <!-- 分页区域 -->
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination> -->
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      total: 0,
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      query: {},
      addDialogVisible: false,
      editDialogVisible: false,
      coinList: [],
      addForm: {
        // address: '',
        // remark: '',
        // uccn: '',
        // hot: 1
      },
      editForm: {},
      formRules: {
        symbol: [{ required: true, message: '请输入符号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入代币地址', trigger: 'blur' }],
        uccn: [{ required: true, message: '请输入官网', trigger: 'blur' }],
        chain: [{ required: true, message: '请输入主链类型', trigger: 'blur' }],
        // remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }]
        // hot: [{ required: true, message: '请选择是否热门', trigger: 'blur' }]
      }
    }
  },
  methods: {
    showAdd() {
      this.addDialogVisible = true
    },
    async getInfo() {
      // console.log('getinfo')
      this.query = this.queryInfo
      const { data: res } = await this.$http.get('coinRecommend/list?' + 'page=' + this.query.pagenum + '&rows=' + this.query.pagesize)
      this.coinList = res.data.appRecommends
      this.total = res.data.total
      // console.log(res)
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    addConfirm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return this.$message.error('请填写完整信息')
        const { data: res } = await this.$http.post('coinRecommend/create', this.addForm)
        // console.log(res)
        if (res.status !== 0) return this.$message.error('添加数据失败')
        this.addDialogVisible = false
        this.getInfo()
      })
    },
    showEditDialog(row) {
      this.editForm = row
      delete this.editForm.createTime
      // console.log(row)
      this.editDialogVisible = true
    },
    async editConfirm() {
      const { data: res } = await this.$http.post('coinRecommend/update', this.editForm)
      // console.log(res)
      if (res.status !== 0) return this.$message.error('修改数据失败')
      this.getInfo()
      this.editDialogVisible = false
    },
    async removeById(Id) {
      // console.log(Id)
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('coinRecommend/delete', { params: { id: Id } })
      if (res.status !== 0) {
        return this.$message.error('删除用户失败！')
      }
      this.$message.success('删除用户成功！')
      this.getInfo()
    }
    // handleSizeChange (newSize) {
    //   this.queryInfo.pagesize = newSize
    //   this.getInfo()
    // },
    // handleCurrentChange(newPage) {
    //   this.queryInfo.pagenum = newPage
    //   this.getInfo()
    // }
  },
  created() {
    this.getInfo()
  }
}
</script>
<style lang='less' scoped>
</style>
