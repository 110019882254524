<template>
  <div class="">
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>tags</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" @click="add()">新增</el-button> -->
      <el-table :data="tagsList" border stripe>
        <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
        <el-table-column label="标签名称" prop="tag" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" align="center" width="80px"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" align="center" width="400px"></el-table-column>
        <!-- <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button type="warning" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      queryInfo: {
        query: '',
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 2
      },
      tagsList: []
    }
  },
  methods: {
    async getInfo () {
      const { data: res } = await this.$http.get('tags/list')
      this.tagsList = res.data
      // console.log(res)
    },
    showEditDialog () {},
    removeUserById () {}
  },
  created () {
    this.getInfo()
  }
}
</script>
<style lang='less' scoped>
</style>
